import React from 'react';

// header: (Quick Menu - Rostek) - Search Input - (Mobile App - Account)

export const GeneralHeader = ({

}) => {
    return (
        <div style={{ backgroundColor: 'red' }}>
            Header
        </div>
    );
}
